import type * as Onboarding from '@temperworks/types/src/types/interfaces/Api/V4/Worker/Onboarding'
import { HttpResponse, http, delay } from 'msw'

export const onboarding = [
  http.get("http://uk.localhost/api/v4/worker/onboarding", async () => {

    const res = {
      "sections": [
      {
        "id": "035546e1-3781-4825-bb92-57edd9f850b2",
        "key": "freeflexer.onboarding.section.start-earning-profile",
        "completed": false,
        "steps": [
          {
            "id": "52fe4d0d-b475-4172-9475-836d0822deab",
            "key": "freeflexer.onboarding.steps.create-account",
            "mandatory": true,
            "show_in_screen": false,
            "completed": false,
            "actions": [
              {
                "id": "3b68be38-8be7-4a75-a995-d502316b44e7",
                "key": "freeflexer.onboarding.actions.create-account",
                "mandatory": true,
                "completed_at": null
              },
              {
                "id": "d266f802-a3b1-4507-b05d-c0e77a49f14b",
                "key": "freeflexer.onboarding.actions.add-address",
                "mandatory": true,
                "completed_at": "2024-11-11T04:57:47+01:00"
              },
              {
                "id": "84225171-ecd2-4a18-9631-f49832edac50",
                "key": "freeflexer.onboarding.actions.add-phone-number",
                "mandatory": true,
                "completed_at": "2024-11-11T04:57:47+01:00"
              }
            ]
          },
          {
            "id": "76f2cae4-d8db-4152-bb0a-41de770bccb9",
            "key": "freeflexer.onboarding.steps.id-verification",
            "mandatory": true,
            "show_in_screen": true,
            "completed": false,
            "actions": [
              {
                "id": "804d79f3-ddec-4307-91cf-393a728b90ec",
                "key": "freeflexer.onboarding.actions.work-eligibility-verified",
                "mandatory": true,
                "completed_at": null
              },
              {
                "id": "f9ad0393-4d79-4e3e-ba6e-d582f8d5cea1",
                "key": "freeflexer.onboarding.actions.birthday-updated",
                "mandatory": true,
                "completed_at": "2024-11-11T04:57:47+01:00"
              }
            ]
          },
          {
            "id": "9fa2bf5b-e4cd-4d84-a0a9-f0466e712e31",
            "key": "freeflexer.onboarding.steps.responsibilities-taken",
            "mandatory": true,
            "show_in_screen": true,
            "completed": false,
            "actions": [
              {
                "id": "7cb0ebd9-6cd6-4f70-a501-2b0a8bf623bb",
                "key": "freeflexer.onboarding.actions.user-agreement-accepted",
                "mandatory": true,
                "completed_at": "2024-11-11T04:57:47+01:00"
              },
              {
                "id": "80a9e10a-60a8-4b3d-a70b-25160a57686e",
                "key": "freeflexer.onboarding.actions.life-as-freeflexer",
                "mandatory": true,
                "completed_at": null
              }
            ]
          }
        ]
      },
      {
        "id": "57e07ebd-164a-4fe5-906e-061cb56f5b56",
        "key": "freeflexer.onboarding.section.optimize-profile",
        "completed": false,
        "steps": [
          {
            "id": "efe87a14-47d2-4d2f-a72f-470e47730d1a",
            "key": "freeflexer.onboarding.steps.add-work-experience",
            "mandatory": false,
            "show_in_screen": true,
            "completed": true,
            "actions": [
              {
                "id": "8fa29468-803f-4283-84b1-c6b67d41e671",
                "key": "freeflexer.onboarding.actions.add-work-experience",
                "mandatory": false,
                "completed_at": "2024-11-11T04:57:47+01:00"
              }
            ]
          },
          {
            "id": "380df6f5-ad0c-4055-b043-abb43b5cb3a6",
            "key": "freeflexer.onboarding.steps.add-bio",
            "mandatory": false,
            "show_in_screen": true,
            "completed": true,
            "actions": [
              {
                "id": "9c126f07-13ee-40ee-952c-33d7a9212031",
                "key": "freeflexer.onboarding.actions.add-bio.description",
                "mandatory": false,
                "completed_at": "2024-11-11T04:57:47+01:00"
              }
            ]
          },
          {
            "id": "452a4dde-c7a5-42ca-929c-c2d383a19634",
            "key": "freeflexer.onboarding.steps.upload-picture",
            "mandatory": false,
            "show_in_screen": true,
            "completed": true,
            "actions": [
              {
                "id": "71a9be3f-5a56-4081-8b7d-cdf998acbb1d",
                "key": "freeflexer.onboarding.actions.upload-picture.description",
                "mandatory": false,
                "completed_at": "2024-11-11T04:57:47+01:00"
              }
            ]
          },
          {
            "id": "410bfa0c-0b67-4c7b-a657-78f36f107e64",
            "key": "freeflexer.onboarding.steps.add-languages",
            "mandatory": true,
            "show_in_screen": true,
            "completed": false,
            "actions": [
              {
                "id": "0f8f50d5-a0d2-4b6f-a108-620b908adb9d",
                "key": "freeflexer.onboarding.actions.add-languages.description",
                "mandatory": true,
                "completed_at": null
              }
            ]
          },
          {
            "id": "ebce0349-4b0f-48e8-a410-1cb0f7511368",
            "key": "freeflexer.onboarding.steps.add-certificates",
            "mandatory": false,
            "show_in_screen": true,
            "completed": false,
            "actions": [
              {
                "id": "7f835811-fa6d-4e3e-ad9e-de209735af1b",
                "key": "freeflexer.onboarding.actions.add-certificates.description",
                "mandatory": false,
                "completed_at": null
              }
            ]
          }
        ]
      }
    ]
    }
    await delay(1000)

    return HttpResponse.json(res)
  }),
]

